import React from "react";

import { EditMealsAndNutritionView, processServerErrors } from "./view";
import { EditFacilityDialog } from "@pages/DeepDivePanel/EditFacilityDialog";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

const EditMealsAndNutrition: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <EditFacilityDialog
      name="EditMealsAndNutrition"
      title="Meals & Nutrition"
      viewPath={`/map/${facility.id}/meals-and-nutrition`}
      processErrors={processServerErrors}
    >
      {(view, setView) => (
        <EditMealsAndNutritionView
          facilityView={view}
          setFacilityView={setView}
        />
      )}
    </EditFacilityDialog>
  );
};

export default EditMealsAndNutrition;
